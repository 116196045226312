(function(wnd) {
    function ToggleItem(parent, params) {
        try {
            this.parent = parent;
            this.label = null;
            this.key = null;
            this.index = 0;
            this.options = {};
            this.value = null;
            this.content = null;
            this.locked = false;
            this.prevButton = null;
            this.nextButton = null;
            this.point = 2;
            this.validInitValueEnabled = false;
            this.shuffleOptions = true;
            this.size = null;
            this.value = null;
            this.initialValue = null;

            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    ToggleItem.prototype = {
        init: function () {
            if (this.value) {
                this.initialValue = this.value;
                this.locked = true;
                return;
            }

            this.value = this.options[this.index];

            if (!this.validInitValueEnabled && typeof this.parent.getValidValue === 'function') {
                var validValue = this.parent.getValidValue(this.key);
                
                if (Array.isArray(validValue)) {
                    console.error('kezeljük a tömb értékeket');
                }

                if (!Array.isArray(validValue) && validValue === this.value) {
                    ++this.index;
                    this.value = this.options[this.index];
                }
            }
        },
        setParams: function (params) {
            if (params.label) this.setLabel(params.label);
            if (params.options) this.setOptions(params.options);
            if (params.key) this.setKey(params.key);
            if (params.point) this.setPoint(params.point);
            if (params.size) this.setSize(params.size);
            if (typeof params.value !== 'undefined') this.setValue(params.value);
            if (typeof params.shuffleOptions !== 'undefined') this.setShuffleOptions(params.shuffleOptions);
            if (typeof params.validInitValueEnabled !== 'undefined') this.setValidInitValueEnabled(params.validInitValueEnabled);
        },
        start: function () {
            this.removeFeedback();
        },
        destroy: function () {
            if (this.prevButton) {
                this.prevButton.off('click');
            }

            if (this.nextButton) {
                this.nextButton.off('click');
            }

            this.content.remove();
        },
        reveal: function (value) {
            if (this.isLocked()) {
                return;
            }

            this.removeFeedback();
            this.content.find('.toggle-item').addClass('revealed');
            this.content.find('.text').html(this.parent.getText(value));
        },
        mark: function (success) {
            if (this.locked) {
                return;
            }

            this.locked = success;
            var feedback = success ? 'success' : 'error';

            this.content.find('.toggle-item').addClass(feedback);

            if (success) {
                this.prevButton.off('click');
                this.nextButton.off('click');
            }
        },
        removeFeedback: function () {
            this.content.find('.toggle-item').removeClass('error')
        },
        changeContent: function () {
            if (!this.parent.isEnabled() || this.isLocked()) {
                return;
            }

            if (this.index < 0) {
                this.index = this.options.length - 1;
            }

            if (this.index === this.options.length) {
                this.index = 0;
            }

            this.value = this.options[this.index];

            this.content.find('.text').html(this.parent.getText(this.value));
        },
        setOptions: function (options) {
            if (!Array.isArray(options)) {
                return;
            }

            this.options = this.shuffleOptions ? shuffleArray(options) : options;
        },
        getHTML: function () {
            var _self = this;
            
            this.content = $('<div>', {
                class: 'toggle-outer',
            });

            if (this.label) {
                this.content.append('<div class="field-label">' + this.parent.getText(this.label) + '</div>');
            }
            
            var item = $('<div>', {
                class: 'toggle-item position-relative ' + (this.size ? this.size : ''),
                html: '<div class="text">' + this.parent.getText(this.value) + '</div>',
            });

            if (!this.initialValue) {
                this.prevButton = $('<div>', {
                    class: 'step prev',
                    html: svg('step-arrow'),
                });
    
                this.nextButton = $('<div>', {
                    class: 'step next',
                    html: svg('step-arrow'),
                });
    
                item.append(this.prevButton);
                item.append(this.nextButton);

                this.prevButton.on('click', function () {
                    --_self.index;
                    _self.changeContent();
                });
    
                this.nextButton.on('click', function () {
                    ++_self.index;
                    _self.changeContent();
                });
            }

            this.content.append(item);

            return this.content;
        },
        getValue: function () {
            return this.value;
        },
        setLabel: function (label) {
            this.label = label;
        },
        setKey: function (key) {
            this.key = key;
        },
        getPoint: function () {
            return this.point;
        },
        setLocked: function (locked) {
            this.locked = locked;
        },
        isLocked: function () {
            return this.locked;
        },
        setShuffleOptions: function (shuffleOptions) {
            this.shuffleOptions = shuffleOptions;
        },
        setValidInitValueEnabled: function (validInitValueEnabled) {
            this.validInitValueEnabled = validInitValueEnabled;
        },
        setSize: function (size) {
            this.size = size;
        },
        setValue: function (value) {
            this.value = value;
        }
    };

    wnd.MuravidekToggleItem = ToggleItem;
})(window);