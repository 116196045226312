(function (wnd) {
    function PuzzleGame(task, params) {
        try {
            this.task = task;
            this.gameHandler = null;
            this.content = null;
            this.image = null;
            this.fields = [];
            this.points = 0;
            this.options = {
                rows: 1,
                cols: 1,
                point: 4,
                size: 'default',
            }      

            if (params) {
                this.setParams(params);
            }
        } catch (error) {
            console.error(error);
        }
    }

    PuzzleGame.prototype = {
        setParams: function (params) {
            if (params.image) this.setImage(params.image);
            if (params.options) this.setOptions(params.options);
        },
        initGameHandler: function (type) {
            switch (type) {
                case 'dragAndDrop':
                    this.gameHandler = new DragAndDropHandler(this, {
                        options: {
                            puzzle: true,
                            image: this.getImage(),
                            rows: this.getOption('rows'),
                            cols: this.getOption('cols'),
                            appendTo: '.puzzle-game-section',
                            imageSize: this.getOption('size'),
                        },
                    });
                    break;
                default:
                    throw Error('Undefined game handler: ' + type);
            }
        },
        getGameHandler: function () {
            return this.gameHandler;
        },
        start: function () {
            for (var i = 0; i < this.fields.length; i++) {
                this.fields[i].start();
            }
        },
        stop: function () {
            for (var i = 0; i < this.fields.length; i++) {
                this.fields[i].stop();
            }
        },
        reveal: function () {
            for (var i = 0; i < this.fields.length; i++) {
                if (!this.fields[i].isLocked()) {
                    this.fields[i].reveal(this.getImage());
                }
            }

            this.task.updateLazyLoad();
        },
        destroy: function () {
            for (var i = 0; i < this.fields.length; i++) {
                this.fields[i].destroy();
            }

            this.content.remove();
        },
        validate: function () {
            var valid = true;

            for (var i = 0; i < this.fields.length; i++) {
                var validField = this.fields[i].getKey() === this.fields[i].getValue();
                this.fields[i].mark(validField);

                if (!validField) {
                    valid = false;
                }
            }

            if (valid) {
                this.addPoints(parseInt(this.getOption('point')));
                for (var i = 0; i < this.fields.length; i++) {
                    this.fields[i].removeFeedback(true);

                    this.content.find('.puzzle-container').append($('<div>', {
                        class: 'confirm-item'
                    }));

                    var _self = this;
                    setTimeout(function () {
                        _self.content.find('.confirm-item').fadeOut(1500);
                    }, 1000);
                }
            }

            return valid;
        },
        getHTML: function () {
            this.content = $('<div>', {
                class: 'game-section puzzle-game-section',
                html: '<div class="puzzle-container"></div>',
            });

            this.drawFields();

            return this.content;
        },
        drawFields: function () {
            for (var i = 0; i < parseInt(this.getOption('rows')); i++) {
                for (var j = 0; j < parseInt(this.getOption('cols')); j++) {
                    var index = (j + 1) + (i * parseInt(this.getOption('cols')));
                    var rowIndex = i + 1;
                    var colIndex = j + 1;

                    var field = new MuravidekTagItem(this, {
                        key: index,
                        puzzle: true,
                        options: {
                            row: rowIndex,
                            col: colIndex,
                        },
                    });

                    this.fields.push(field);

                    var fieldContent = field.getHTML();
                    var classes = ['puzzle-outer'];
                    classes.push('row-' + rowIndex);
                    classes.push('col-' + colIndex);
                    classes.push('item-' + index);
                    classes.push('size-' + this.getOption('size'))

                    if (rowIndex === parseInt(this.getOption('rows'))) {
                        classes.push('last-row');
                    }

                    if (colIndex === parseInt(this.getOption('cols'))) {
                        classes.push('last-col');
                    }

                    fieldContent.addClass(classes.join(' '))

                    this.content.find('.puzzle-container').append(fieldContent);
                }
            }
        },
        setImage: function (image) {
            this.image = base_url + image;
        },
        getImage: function () {
            return this.image;
        },
        setOptions: function (options) {
            this.options = $.extend({}, this.options, options);
        },
        getOptions: function () {
            return this.options;
        },
        getOption: function (key) {
            return this.options[key] || null;
        },
        addPoints: function (point) {
            this.points += point * this.task.getMultiplier();
        },
        getPoints: function () {
            return this.points;
        },
        isEnabled: function () {
            return this.task.isEnabled();
        },
        getText: function (key) {
            return this.task.getText(key);
        },
    };

    wnd.PuzzleGame = PuzzleGame;
})(window);