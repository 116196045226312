(function(wnd) {
    function MemoryItem(task, params) {
        try {
            this.task = task;
            this.value = null;
            this.pair = null;
            this.point = 1;
            this.type = '';
            this.src = '';
            this.label = '';
            this.locked = false;
            this.opened = false;
            this.content = '';

            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error)
        }
    }

    MemoryItem.prototype = {
        task: null,
        delay: 1200,
        init: function () {

        },
        setParams: function (params) {
            this.value = params.value;
            this.pair = params.pair;
            this.type = params.type;
            
            if (params.point) {
                this.point = params.point;
            }

            if (params.src) {
                this.src = params.src;
            }

            if (params.label) {
                this.label = this.task.getText(params.label);
            }
        },
        getHTML: function() {
            this.content = $('<div>', {
                class: 'memory-item ' + this.type + '-item',
                html: '<div class="back-face">' + svg('muravidek-logo') + '</div>'
            });
            

            var frontFace = $('<div>', {
                class: 'front-face',
                html: '<div class="inner"></div>',
            });
            switch (this.type) {
                case 'text':
                    frontFace.find('.inner').append(this.task.getText(this.label));
                    break;
                case 'image':
                    frontFace.find('.inner').append('<img src="' + base_url + this.src + '" />');
                    break;
            }

            this.content.append(frontFace);

            this.addDOMEvents();

            return this.content;
        },
        addDOMEvents: function () {
            var _self = this;

            this.content.on('click', function () {
                _self.task.handleFlip(_self);
            });
        },
        close: function () {
            var _self = this;
            
            setTimeout(function() {
                _self.content.find('.front-face .inner').removeClass('error')
                _self.content.removeClass('flipped');
                _self.opened = false;
            }, this.delay);
        },
        mark: function (success) {
            var _self = this;

            this.locked = success;
            if (success) {
                var feedback = success ? 'success' : 'error';
                setTimeout(function() {
                    _self.content.find('.front-face .inner').addClass(feedback);
                }, 200);
            }
        },
        flip: function () {
            this.content.toggleClass('flipped');
            this.opened = this.content.hasClass('flipped');
        },
        destroy: function () {
            this.content.off();
            this.content.remove();
        },
        getPoint: function () {
            return this.point;
        },
        isLocked: function () {
            return this.locked;
        }
    }

    function MemoryGame(task, params) {
        try {
            this.task = task;
            this.content = null;
            this.textValueContent = null;
            this.alternate = false;
            this.started = false;
            this.textIndex = 0;
            this.textFields = [];
            this.options = [];

            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    MemoryGame.prototype = {
        openedCard: null,
        found: 0,
        pairs: 0,
        finished: false,
        points: 0,
        init: function () {},
        handleFlip: function (item) {
            if (this.alternate) {
                return this.handleAlternateFlip(item);
            }

            if (!this.alternate) {
                return this.handleDefaultFlip(item);
            }            
        },
        handleDefaultFlip: function (item) {
            if (!this.task.enabled || item.locked) {
                return;
            }

            item.flip();
            if (!item.opened) {
                this.openedCard = null;
                return;
            }

            if (this.openedCard === null) {
                this.openedCard = item;
                return;
            }

            var valid = this.openedCard.value === item.pair;
            item.mark(valid);
            this.openedCard.mark(valid);
            
            if (valid) {
                this.addPoints(item.getPoint());
                this.addPoints(this.openedCard.getPoint());

                this.found++;
                if (this.found === this.pairs) {
                    this.finished = true;
                    this.task.stop();
                }
            } else {
                this.openedCard.close();
                item.close();
            }

            this.openedCard = null;
        },
        handleAlternateFlip: function (item) {
            if (!this.task.enabled || !this.started || item.locked) {
                return;
            }

            item.flip();
            var textItem = this.textFields[this.textIndex];
            var valid = item.pair === textItem.value;
            if (valid) {
                textItem.found = true;
                item.mark(true);
                this.found++;
                
                this.addPoints(item.getPoint());
            } else {
                item.close();
            }

            this.textIndex++;
            if (this.validate() || this.textIndex === this.textFields.length) {
                this.finished = true;
                this.task.stop();
            } else {
                this.drawText();
            }
        },
        setParams: function (params) {
            if (typeof params.alternate !== 'undefined') this.setAlternate(params.alternate);
            if (params.options) this.setOptions(params.options);
            if (params.textFields) this.setTextFields(params.textFields);
        },
        setOptions: function (options) {
            options = shuffleArray(options);
            for (var i = 0; i < options.length; i++) {
                this.options.push(new MemoryItem(this, options[i]));
            }

            this.pairs = this.isAlternate() ? this.options.length : this.options.length / 2;
        },
        setTextFields: function (textFields) {
            if (!Array.isArray(textFields)) {
                return;
            }
            
            for (var i = 0; i < textFields.length; i++) {
                this.textFields.push({
                    value: textFields[i].value,
                    label: this.getText(textFields[i].label),
                    found: false,
                });
            }
        },
        setAlternate: function (alternate) {
            this.alternate = alternate;
        },
        isAlternate: function () {
            return this.alternate;
        },
        getText: function (key) {
            return this.task.getText(key);
        },
        getHTML: function () {
            this.content = $('<div>', {
                class: 'game-section memory-game-section'
            });

            if (this.isAlternate()) {
                this.textValueContent = $('<div>', {
                    class: 'text-value-content hidden',
                });

                this.content.append(this.textValueContent);
            }

            for (var i = 0; i < this.options.length; i++) {
                this.content.append(this.options[i].getHTML());
            }

            return this.content;
        },
        destroy: function () {
            for (var i = 0; i < this.options.length; i++) {
                this.options[i].destroy();
            }
        },
        validate: function () {
            return this.pairs === this.found;
        },
        addPoints: function (point) {
            this.points += point * this.task.getMultiplier();
        },
        getPoints: function () {
            return this.points;
        },
        stop: function () {
            if (this.isAlternate()) {
                this.textValueContent.addClass('hidden');
            }

            if (!this.openedCard) {
                return;
            }

            if (!this.openedCard.locked) {
                this.openedCard.close();
            }

            this.openedCard = null;
        },
        start: function () {
            if (!this.isAlternate()) {
                return;
            }

            $('body,html').animate({
                scrollTop: 0
            }, 'slow');
            
            this.started = false;
            for (var i = 0; i < this.options.length; i++) {
                if (!this.options[i].isLocked()) {
                    this.options[i].flip();
                }
            }
        },
        startAlternateGame: function () {
            for (var i = 0; i < this.options.length; i++) {
                if (!this.options[i].isLocked()) {
                    this.options[i].flip();
                }
            }

            this.started = true;
            this.textIndex = 0;
            this.textValueContent.removeClass('hidden');
            this.drawText();
        },
        drawText: function () {
            while (this.textFields[this.textIndex].found || this.textIndex === this.textFields.length) {
                this.textIndex++;
            }

            var item = this.textFields[this.textIndex];
            this.textValueContent.html('<div class="searched-text">' + item.label + '</div>');
        },
        intervalEnded: function () {
            if (this.isAlternate()) {
                this.startAlternateGame();
                return true;
            }

            return false;
        },
    }

    wnd.MemoryGame = MemoryGame;
})(window);