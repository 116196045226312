var windowWidth = window.innerWidth;
var windowHeight = window.innerHeight;
var viewportHeight = window.visualViewport.height;

$(document).ready(function() {
    window.addEventListener('resize', function () {
        if (windowWidth === window.innerWidth && windowHeight === window.innerHeight && viewportHeight === window.visualViewport.height) {
            return;
        }

        if (windowWidth !== window.innerWidth) {
            windowWidth = window.innerWidth;
            $('.resize-sensitive').trigger('muravidek.window_width_changed', windowWidth);
        }

        if (windowHeight !== window.innerHeight || viewportHeight !== window.visualViewport.height) {
            windowHeight = window.innerHeight;
            $('.resize-sensitive').trigger('muravidek.window_height_changed', windowHeight);
        }
    });
})

function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
}

function stripTags(text) {
    var element = document.createElement('div');
    element.innerHTML = text;

    return element.innerText || element.textContent || text;
}

function compareTexts(a, b) {
    a = a.trim();
    b = b.trim();

    return a.toLowerCase() === b.toLowerCase();
}

function svg(icon) {
    return '<span class="svg"><svg class="icon ' + icon + '"><use xlink:href="#' + icon + '" /></svg></span>';
}