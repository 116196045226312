(function (wnd) {
    function TagItem(parent, params) {
        try {
            this.parent = parent;   
            this.label = null;
            this.key = null;
            this.value = null;
            this.locked = null;
            this.point = 2;
            this.content = null;
            this.empty = true;
            this.multipleEnabled = false;
            this.puzzle = false;
            this.options = {
                row: null,
                col: null,
                lockInitValue: true,
            };
            
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    TagItem.prototype = {
        init: function () {
            if (this.value && this.getOption('lockInitValue')) {
                this.locked = true;
            }
        },
        addDOMEvents: function () {
            var _self = this;

            this.content.find('.tag-field').on('muravidek.tag_dropped', function () {
                _self.checkCurrentState();
            });
        },
        checkCurrentState: function () {
            if (this.locked) {
                return;
            }

            var tags = this.content.find('.tag-item');
            this.setEmpty(tags.length <= 0);
            
            if (this.isEmpty()) {
                this.setValue(null);
                return;
            }

            var value = $(tags[0]).data('value');      
            this.setValue(value);
        },
        setParams: function (params) {
            if (params.point) this.setPoint(params.point);
            if (params.key) this.setKey(params.key);
            if (params.label) this.setLabel(params.label);
            if (params.value) this.setValue(params.value);
            if (typeof params.puzzle !== 'undefined') this.setPuzzle(params.puzzle);
            if (params.options) this.setOptions(params.options);
        },
        start: function () {
            this.removeFeedback();
        },
        destroy: function () {
            this.content.find('.tag-field').off('muravidek.tag_dropped');
            this.content.remove();
        },
        reveal: function (value) {
            if (this.isLocked()) {
                return;
            }

            this.content.find('.tag-field').html('');
            this.removeFeedback();
            this.content.find('.tag-field').addClass('revealed');

            if (this.isPuzzle()) {
                this.revealPuzzleTag(value);
            } else {
                this.revealSimpleTag(value)
            }
        },
        revealSimpleTag: function (value) {
            this.content.find('.tag-field').append($('<div>', {
                class: 'tag-item revealed',
                html: '<div class="text">' + this.parent.getText(value) + '</div>',
            }));

            $(document).trigger('muravidek.reveal_tag', {
                key: this.getKey(),
                value: value,
            });
        },
        revealPuzzleTag: function (image) {
            var classes = ['puzzle-item', 'lazy'];
            classes.push('row-' + this.getOption('row'));
            classes.push('col-' + this.getOption('col'));
            classes.push('item-' + this.getKey());

            this.content.find('.tag-field').append($('<div>', {
                class: 'tag-item ' + classes.join(' '),
                'data-bg': image
            }));

            $(document).trigger('muravidek.reveal_tag', this.getKey());
        },
        mark: function (success) {
            if (this.locked) {
                return;
            }

            this.locked = success;
            var feedback = success ? 'success' : 'error';
            this.removeFeedback();

            this.content.find('.tag-field').addClass(feedback);

            if (this.locked) {
                this.content.find('.tag-item').addClass('locked');
            }
        },
        removeFeedback: function (all) {
            this.content.find('.tag-field').removeClass('error');

            if (all) {
                this.content.find('.tag-field').removeClass('success');
            }
        },
        getHTML: function () {
            this.content = $('<div>', {
                class: 'tag-outer',
            });

            if (this.isPuzzle()) {
                this.drawPuzzleTag();
            } else {
                this.drawSimpleTag();
            }            

            if (!this.value || !this.getOption('lockInitValue')) {
                this.addDOMEvents();
            }

            return this.content;
        },
        drawSimpleTag: function () {
            if (this.label) {
                this.content.append('<div class="field-label">' + this.parent.getText(this.label) + '</div>');
            }

            var item = $('<div>', {
                class: 'tag-field ' + this.key + '-tag-field',
                'data-key': this.key,
            });

            if (this.value) {
                item.append('<div class="tag-item ' + this.getKey() + '-item" data-key="' + this.key + '" data-value="' + this.value + '">' + this.parent.getText(this.value) + '</div>');

                if (this.getOption('lockInitValue')) {
                    this.setEmpty(false);
                    item.find('.tag-item').addClass('locked hint');
                    item.addClass('locked');
                }
            }

            this.content.append(item);
        },
        drawPuzzleTag: function () {
            var item = $('<div>', {
                class: 'tag-field puzzle-field',
            });

            this.content.append(item);
        },
        setKey: function (key) {
            this.key = key;
        },
        getKey: function () {
            return this.key;
        },
        setLabel: function (label) {
            this.label = label;
        },
        setValue: function (value) {
            this.value = value;
        },
        getValue: function () {
            return this.value;
        },
        setPoint: function (point) {
            this.point = point;
        },
        getPoint: function () {
            return this.point;
        },
        setEmpty: function (empty) {
            this.empty = empty;
        },
        isEmpty: function () {
            return this.empty;
        },
        isLocked: function () {
            return this.locked;
        },
        isMultipleEnabled: function () {
            return this.multipleEnabled;
        },
        setPuzzle: function (puzzle) {
            this.puzzle = puzzle;
        },
        isPuzzle: function () {
            return this.puzzle;
        },
        setOptions: function (options) {
            this.options = $.extend({}, this.options, options);
        },
        getOption: function (key) {
            if (typeof this.options[key] !== 'undefined') {
                return this.options[key];
            }

            return null;
        },
    }

    wnd.MuravidekTagItem = TagItem;
})(window);