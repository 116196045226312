(function (wnd) {
    function DragAndDropHandler(task, params) {
        try {
            this.task = task;
            this.fields = [];
            this.tags = [];
            this.sourceIsCloud = false;
            this.hint = null;
            
            this.selectors = {
                tag: '.tag-item',
                zone: '.tag-field',
                container: '.tags-container',
            };

            this.options = {
                shuffleTags: true,
                multiple: false,
                puzzle: false,
                checkKeyBeforeDrop: false,
                rows: 1,
                cols: 1,
                appendTo: null,
                imageSize: 'default',
                containerClass: null,
            };

            if (params) {
                this.setParams(params);
            }
        } catch (error) {
            console.error(error);
        }
    }

    DragAndDropHandler.prototype = {
        init: function () {
            var _self = this;

            $(document).on('muravidek.reveal_tag', function (evt, data) {
                _self.removeFromCloud(data);
            });
            
            $(this.getSelector('tag')).on('touchstart', function() {
                $('body').css({
                    overflow: 'hidden'
                });
            });

            $(this.getSelector('tag')).on('touchend', function() {
                $('body').css({
                    overflow: 'auto'
                });
            });

            $(this.getSelector('tag')).on('touchmove', function(e) {
                e.preventDefault();
            });

            interact(this.getSelector('tag')).draggable({
                inertia: true,
                autoScroll: true,
                listeners: {
                    start: function (event) {
                        _self.sourceIsCloud = event.target.parentNode.classList.contains('tags-container');
                    },
                    move: function (event) {
                        var target = event.target;
                        if (target.parentNode.classList.contains('success') || target.parentNode.classList.contains('locked') || !_self.task.isEnabled()) {
                            return;
                        }

                        if (!target.classList.contains('dragging')) {
                            target.classList.add('dragging');
                        }

                        var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
                        var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

                        target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

                        target.setAttribute('data-x', x);
                        target.setAttribute('data-y', y);
                    },
                    end: function (event) {
                        try {
                            var target = event.relatedTarget.querySelector(_self.getSelector('tag'));
                            target.style.transform = 'none';
                            target.removeAttribute('data-x');
                            target.removeAttribute('data-y');

                            if (target.classList.contains('dragging')) {
                                target.classList.remove('dragging');
                            }
                        } catch {}
                        
                        event.target.style.transform = 'none';
                        event.target.removeAttribute('data-x');
                        event.target.removeAttribute('data-y');

                        if (event.target.classList.contains('dragging')) {
                            event.target.classList.remove('dragging');
                        }
                    }
                },
                modifiers: [
                    interact.modifiers.restrictRect({
                        restriction: '.task-container',
                        endOnly: true,
                    }),
                ],
            });

            interact(this.getSelector('zone')).dropzone({
                accept: this.getSelector('tag'),
                overlap: 0.3,
                ondrop: function (event) {
                    var tag = $(event.relatedTarget);
                    var field = $(event.target);
                    var key = tag.data('key');
                    if (field.hasClass('success') || field.hasClass('locked') || (_self.getOption('checkKeyBeforeDrop') && key !== field.data('key'))) {
                        return;
                    }
                    
                    if (!field.hasClass('multiple-enabled')) {
                        _self.handleDuplicate(field, tag);
                    }
                    
                    if (!_self.isMultiple(key) || !_self.sourceIsCloud) {
                        field.append(tag);
                    } else {
                        var clone = tag.clone();
                        field.append(clone);
                    }

                    $(_self.getSelector('zone') + ':not(.success)').each(function() {
                        $(this).trigger('muravidek.tag_dropped');
                    });
                },
            });

            interact(this.getSelector('container')).dropzone({
                accept: this.getSelector('tag'),
                overlap: 0.05,
                ondrop: function (event) {
                    var tag = $(event.relatedTarget);
                    var key = tag.data('key');
                    var field = $(event.target);
                    var parent = tag.parent();
                    
                    if (parent.hasClass(_self.getSelector('container').replace('.', ''))) {
                        return;
                    }

                    if (_self.isMultiple(key)) {
                        tag.remove();
                    } else {
                        field.append(tag);
                    }
                    
                    $(_self.getSelector('zone') + ':not(.success)').each(function() {
                        $(this).trigger('muravidek.tag_dropped');
                    });
                }
            });
        },
        setParams: function (params) {
            if (params.fields) this.setFields(params.fields);
            if (params.hint) this.setHint(params.hint);
            if (params.options) this.setOptions(params.options);
            if (params.selectors) this.setSelectors(params.selectors);
        },
        getHTML: function () {
            this.content = $('<div>', {
                class: this.getSelector('container').replace('.', '') + ' display-flex flex-wrap',
            });
            
            if (this.getHint()) {
                this.content.append('<div class="tag-hint-content">' + this.task.getText(this.getHint()) + '</div>');
            }

            if (this.getOption('containerClass')) {
                this.content.addClass(this.getOption('containerClass'));
            }
            
            if (this.isPuzzle()) {
                this.drawPuzzleItems();
            } else {
                this.drawSimpleItems();
            }            

            return this.content;
        },
        drawPuzzleItems: function () {
            var items = [];
            for (var i = 0; i < parseInt(this.getOption('rows')); i++) {
                for (var j = 0; j < parseInt(this.getOption('cols')); j++) {
                    var index = (j + 1) + (i * parseInt(this.getOption('cols')));
                    var classes = ['lazy', 'puzzle-item'];
                    classes.push('row-' + (i + 1));
                    classes.push('col-' + (j + 1));
                    classes.push('item-' + index);
                    classes.push('size-' + this.getOption('imageSize'))
                    
                    items.push($('<div>', {
                        class: classes.join(' ') + ' ' + this.getSelector('tag').replace('.', ''),
                        'data-bg': this.getOption('image'),
                        'data-value': index
                    }));
                }
            }

            items = shuffleArray(items);
            for (var i = 0; i < items.length; i++) {
                this.content.append(items[i]);
            }
        },
        drawSimpleItems: function () {
            var tags = this.options.shuffleTags ? shuffleArray(this.tags) : this.tags;
            for (var i = 0; i < tags.length; i++) {
                this.content.append($('<div>', {
                    class: this.getSelector('tag').replace('.', '') + ' ' + tags[i].key + '-item',
                    html: '<div class="text">' + this.task.getText(tags[i].value) + '</div>',
                    'data-value': tags[i].value,
                    'data-key': tags[i].key,
                }));
            } 
        },
        setFields: function (fields) {
            if (!Array.isArray(fields)) {
                return;
            }

            for (var i = 0; i < fields.length; i++) {
                if (fields[i].type === 'tag') {
                    this.fields.push(fields[i]);
                    
                    if (typeof fields[i].hideFromCloud !== 'undefined' && fields[i].hideFromCloud) {
                        continue;
                    }
                    
                    for (var j = 0; j < fields[i].options.length; j++) {
                        var value = fields[i].options[j];
                        var tag = {
                            value: value,
                            key: fields[i].key,
                        }

                        if (typeof fields[i].hints !== 'undefined' && typeof fields[i].hints[value] !== 'undefined') {
                            tag.hint = fields[i].hints[value];
                        }

                        this.tags.push(tag)
                    }
                }
            }
        },
        destroy: function () {
            $(this.getSelector('tag')).off('touchstart');
            $(this.getSelector('tag')).off('touchend');
            $(this.getSelector('tag')).off('touchmove');

            $(document).off('muravidek.reveal_tag');
            
            interact(this.getSelector('tag')).unset();
            interact(this.getSelector('zone')).unset();
            interact(this.getSelector('container')).unset();
        },
        setSelectors: function (selectors) {
            this.selectors = $.extend({}, this.selectors, selectors);
        },
        getSelector: function (key) {
            return this.selectors[key] ? this.selectors[key] : key
        },
        setHint: function (hint) {
            this.hint = hint;
        },
        getHint: function () {
            return this.hint;
        },
        setOptions: function (options) {
            this.options = $.extend({}, this.options, options);
        },
        getOption: function (key) {
            return this.options[key] || null;
        },
        getOptions: function () {
            return this.options;
        },
        isMultiple: function (key) {
            var field = key ? this.getField(key) : null;
            if (field !== null) {
                return field.multiple ?? false;
            }

            return false;
        },
        removeFromCloud: function (data) {
            var key = typeof data === 'object' ? data.key : null;
            var value = typeof data === 'object' ? data.value : data;
            if (key && this.isMultiple(key)) {
                return;
            }

            $(this.getSelector('container')).find('[data-value="' + value + '"]').remove();
        },
        isPuzzle: function () {
            return this.options.puzzle;
        },
        getField: function (key) {
            var field = this.fields.filter(function (item) {
                return item.key === key;
            });

            if (field.length === 0) {
                return null;
            }

            return field[0];
        },
        handleDuplicate: function (tagField, tag) {
            var key = tag.data('key');
            var currentTag = tagField.find(this.getSelector('tag'));
            if (currentTag.length === 0) {
                return;
            }
            
            if (this.isMultiple(key)) {
                currentTag.remove();
                return;
            }
            
            var field = this.getField(key);
            var action = field.duplicateAction ?? 'default';
            switch (action) {
                case 'change':
                    tag.parent().append(currentTag);
                    break;
                default:
                    $(this.getSelector('container')).append(currentTag);
                    break;
            }
        }
    };

    wnd.DragAndDropHandler = DragAndDropHandler;
})(window);