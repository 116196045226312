(function (wnd) {
    function CrossWordItem(task, params) {
        try {
            this.task = task;
            this.word = null;
            this.hint = null;
            this.index = 0;
            this.indent = 0;
            this.point = 2;
            this.fields = [];
            this.locked = false;
            this.highlighted = 0;

            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    CrossWordItem.prototype = {
        init: function () {
            this.initFields();
        },
        addDOMEvents: function () {
            var _self = this;
            
            this.fields.forEach(function (field, index) {
                var input = field.input;

                input.on('input', function (evt) {
                    var value = $(this).val();
                    var nextIndex = index + 1;
                    while (nextIndex < _self.fields.length && _self.fields[nextIndex].isLocked()) {
                        nextIndex++;
                    }

                    if (value && nextIndex < _self.fields.length) {
                        _self.fields[nextIndex].input.focus();
                    }
                });

                input.on('keydown', function (evt) {
                    if (evt.key === 'Backspace') {
                        var value = $(this).val();
                        var prevIndex = index - 1;
                        while (prevIndex >= 0 && _self.fields[prevIndex].isLocked()) {
                            prevIndex--;
                        }
                        
                        if (!value && prevIndex >= 0) {
                            _self.fields[prevIndex].input.focus();
                        }
                    }
                });

                input.on('focus', function () {
                    $(this).parent().addClass('focused');
                    _self.markFields(true);
                });

                input.on('blur', function () {
                    $(this).parent().removeClass('focused');

                    setTimeout(function () {
                        var focused = _self.fields.some(function (item) {
                            return item.input.is(':focus');
                        });

                        if (!focused) {
                            _self.markFields(false);
                        }
                    }, 10);
                });
            });

            this.content.find('.indent-item').on('click', function () {
                var activate = !_self.content.find('.hint-content').hasClass('active');
                _self.changeHintContentVisibility(activate);
            });
        },
        setParams: function (params) {
            if (typeof params.highlighted === 'number') this.setHighlighted(params.highlighted);
            if (typeof params.indent === 'number') this.setIndent(params.indent);
            if (params.index) this.setIndex(params.index);
            if (params.word) this.setWord(params.word);
            if (params.hint) this.setHint(params.hint);
        },
        getHTML: function () {
            this.content = $('<div>', {
                class: 'cross-word-item resize-sensitive',
                html: '<div class="hint-content"><span class="index bold-fweight">' + this.getIndex() + '.</span>&nbsp;&nbsp;' + this.getHint() + '</div>',
            });

            var wide = this.task.getMaxWide() + 1;
            var inputContent = $('<div>', {
                class: 'inputs-content display-grid align-items-center grid-' + wide + '-columns',
                html: '<div class="indent-item wide-' + (this.getIndent() + 1) + '"><div class="index">' + this.getIndex() + '</div></div>'
            });

            for (var i = 0; i < this.fields.length; i++) {
                var fieldHtml = this.fields[i].getHTML();
                if (i === this.getHighlighted()) {
                    fieldHtml.addClass('highlighted');
                }
                inputContent.append(fieldHtml);
            }

            this.content.append(inputContent);

            this.addDOMEvents();

            return this.content;
        },
        start: function () {
            for (var i = 0; i < this.fields.length; i++) {
                this.fields[i].start();
            }
        },
        stop: function () {
            for (var i = 0; i < this.fields.length; i++) {
                this.fields[i].stop();
            }
        },
        destroy: function () {
            for (var i = 0; i < this.fields.length; i++) {
                this.fields[i].destroy();
            }

            this.content.find('.indent-item').off();
            this.content.off();

            this.content.remove();
        },
        reveal: function () {
            for (var i = 0; i < this.fields.length; i++) {
                this.fields[i].reveal();
            }
        },
        validate: function () {
            var valid = true;
            if (this.isLocked()) {
                return valid;
            }
            
            for (var i = 0; i < this.fields.length; i++) {
                var field = this.fields[i];
                var validField = compareTexts(field.getExpectedValue(), field.getValue());
                if (!validField) {
                    valid = false;
                }

                if (!field.isLocked()) {
                    field.mark(validField);
                }
            }

            if (valid) {
                this.task.addPoints(this.point);
                this.setLocked(true);
            }

            return valid;
        },
        markFields: function (active) {
            for (var i = 0; i < this.fields.length; i++) {
                if (active) {
                    this.fields[i].content.addClass('active');
                } else {
                    this.fields[i].content.removeClass('active');
                }
            }

            this.changeHintContentVisibility(active);
        },
        changeHintContentVisibility: function (activate) {
            if (activate) {
                $('.hint-content').removeClass('active');
                this.content.find('.hint-content').addClass('active');
            } else {
                this.content.find('.hint-content').removeClass('active');
            }
        },
        initFields: function () {
            var word = this.getWord();
            if (!word) {
                throw Error('The word must be set for item.');
            }

            for (var i = 0; i < word.length; i++) {
                this.fields.push(new SingleTextItem(this, {
                    expectedValue: word[i],
                    maxLength: 1,
                }));
            }
        },
        setIndex: function (index) {
            this.index = index;
        },
        getIndex: function () {
            return this.index;
        },
        setHighlighted: function (highlighted) {
            this.highlighted = highlighted;
        },
        getHighlighted: function () {
            return this.highlighted;
        },
        setIndent: function (indent) {
            this.indent = indent;
        },
        getIndent: function () {
            return this.indent;
        },
        setWord: function (word) {
            this.word = this.getText(word);
        },
        getWord: function () {
            return this.word;
        },
        setHint: function (hint) {
            this.hint = this.getText(hint);
        },
        getHint: function () {
            return this.hint;
        },
        setPoint: function (point) {
            this.point = point;
        },
        getPoint: function () {
            return this.point;
        },
        setLocked: function (locked) {
            this.locked = locked;
        },
        isLocked: function () {
            return this.locked;
        },
        getLength: function () {
            return this.word.length;
        },
        getText: function (key) {
            return this.task.getText(key);
        },
        isEnabled: function () {
            return this.task.isEnabled();
        },
    };
    
    function CrossWordsGame(task, params) {
        try {
            this.task = task;
            this.points = 0;
            this.items = [];

            if (params) {
                this.setParams(params);
            }
        } catch (error) {
            console.error(error);
        }
    }

    CrossWordsGame.prototype = {
        setParams: function (params) {
            if (params.words) this.initWords(params.words);
        },
        getHTML: function () {
            this.content = $('<div>', {
                class: 'game-section cross-words-game-section display-grid'
            });

            var hintsContainer = $('<div>', {
                class: 'hints-container',
            });
            
            var crossWordsContainer = $('<div>', {
                class: 'cross-words-container',
            });

            for (var i = 0; i < this.items.length; i++) {
                var item = this.items[i];
                hintsContainer.append('<div class="row"><span class="index bold-fweight">' + (i + 1) + '.</span>&nbsp;&nbsp;' + item.getHint() + '</div>')
                crossWordsContainer.append(item.getHTML());
            }

            this.content.append(hintsContainer);
            this.content.append(crossWordsContainer);

            return this.content;
        },
        start: function () {
            for (var i = 0; i < this.items.length; i++) {
                this.items[i].start();
            }
        },
        stop: function () {
            for (var i = 0; i < this.items.length; i++) {
                this.items[i].stop();
            }
        },
        destroy: function () {
            for (var i = 0; i < this.items.length; i++) {
                this.items[i].destroy();
            }

            this.content.remove();
        },
        reveal: function () {
            for (var i = 0; i < this.items.length; i++) {
                this.items[i].reveal();
            }
        },
        validate: function () {
            var valid = true;
            for (var i = 0; i < this.items.length; i++) {
                if (!this.items[i].validate()) {
                    valid = false;
                }
            }

            return valid;
        },
        getMaxWide: function () {
            var wide = 0;
            for (var i = 0; i < this.items.length; i++) {
                var fieldLength = this.items[i].getLength() + this.items[i].getIndent();
                if (fieldLength > wide) {
                    wide = fieldLength;
                }
            }
            return wide;
        },
        initWords: function (words) {
            for (var i = 0; i < words.length; i++) {
                this.items.push(new CrossWordItem(this, words[i]));
            }
        },
        getText: function (key) {
            return this.task.getText(key);
        },
        isEnabled: function () {
            return this.task.isEnabled();
        },
        addPoints: function (point) {
            this.points += point * this.task.getMultiplier();
        },
        getPoints: function () {
            return this.points;
        },
    };

    wnd.CrossWordsGame = CrossWordsGame;
})(window);