(function(wnd) {
    function CheckableItem(task, params) {
        try {
            this.task = task;
            this.label = null;
            this.content = null;
            this.value = null;
            this.selected = false;
            this.locked = false;
            this.type = 'default';
            this.point = 2;

            if (params) {
                this.setParams(params);
            }
        } catch (error) {
            console.error(error);
        }
    }

    CheckableItem.prototype = {
        setParams: function (params) {
            if (typeof params.value !== 'undefined') this.setValue(params.value);
            if (params.label) this.setLabel(params.label);
            if (params.type) this.setType(params.type);
        },
        addDOMEvents: function () {
            var _self = this;

            this.content.on('click', function () {
                if (_self.isLocked() || !_self.task.isEnabled()) {
                    return;
                }

                _self.setSelected(!_self.isSelected());
                _self.task.handleCheck(_self);
            });
        },
        start: function () {
            this.removeFeedback();
        },
        destroy: function () {
            this.content.off('click');
            this.content.remove();
        },
        reveal: function (valid) {
            if (valid) {
                this.content.addClass('revealed');
                this.setSelected(true);
            } else {
                this.content.addClass('revealed-invalid');
            }
        },
        mark: function (success) {
            this.setLocked(success);
            var feedback = success ? 'success' : 'error';

            this.content.addClass(feedback);
            if (success) {
                this.content.off('click');
            }
        },
        removeFeedback: function () {
            this.content.removeClass('error');
        },
        getHTML: function () {
            var classes = [this.getType() + '-item'];
            if (this.isSelected()) {
                classes.push('selected');
            }

            switch (this.type) {
                case 'bool':
                    classes.push(this.value ? 'true-item' : 'false-item');
                    break;
            }

            this.content = $('<div>', {
                class: 'checkable-item ' + classes.join(' '),
                html: '<div class="text">' + this.task.getText(this.getLabel()) + '</div>',
            });
            
            this.addDOMEvents();

            return this.content;
        },
        setLabel: function (label) {
            this.label = label;
        },
        getLabel: function () {
            return this.label;
        },
        setType: function (type) {
            this.type = type;
        },
        getType: function () {
            return this.type;
        },
        setPoint: function (point) {
            this.point = point;
        },
        getPoint: function () {
            return this.point;
        },
        setValue: function (value) {
            this.value = value;
        },
        getValue: function () {
            return this.value;
        },
        setSelected: function (selected) {
            this.selected = selected;
            if (this.content) {
                if (selected) {
                    this.content.addClass('selected');
                } else {
                    this.content.removeClass('selected');
                }
            }
        },
        isSelected: function () {
            return this.selected;
        },
        setLocked: function (locked) {
            this.locked = locked;
        },
        isLocked: function () {
            return this.locked;
        }
    };

    wnd.MuravidekCheckableItem = CheckableItem;
})(window);