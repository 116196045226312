(function(wnd) {
    function QuestionItem(task, params) {
        try {
            this.task = task;
            this.content = null;
            this.values = null;
            this.fields = [];
            this.items = [];
            this.multiple = false;
            this.question = null;
            this.type = 'type';
            this.options = [];
            this.fieldOptions = {};
            
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    QuestionItem.prototype = {
        init: function () {
            this.initFields();
        },
        setParams: function (params) {
            if (params.question) this.setQuestion(params.question);
            if (typeof params.multiple !== 'undefined') this.setMultiple(params.multiple);
            if (typeof params.values !== 'undefined') this.setValues(params.values);
            if (params.items) this.setItems(params.items);
            if (params.type) this.setType(params.type);
            if (params.options) this.setOptions(params.options);
            if (params.fieldOptions) this.setFieldOptions(params.fieldOptions);
        },
        start: function () {
            for (var i = 0; i < this.fields.length; i++) {
                this.fields[i].start();
            }
        },
        stop: function () {},
        reveal: function () {
            var missingValues = this.getMissingValues();
            
            for (var i = 0; i < this.fields.length; i++) {
                if (!this.fields[i].isLocked() && typeof this.fields[i].reveal === 'function') {
                    switch (this.type) {
                        case 'checkable':
                        case 'bool':
                            this.fields[i].reveal(missingValues.indexOf(this.fields[i].getValue()) >= 0);
                            break;
                        case 'toggle':
                        case 'text':
                            var value = Array.isArray(missingValues) ? missingValues.shift() : missingValues;
                            this.fields[i].reveal(value);
                            break;
                    }
                }
            }
        },
        destroy: function () {
            for (var i = 0; i < this.fields.length; i++) {
                this.fields[i].destroy();
            }

            this.content.remove();
        },
        validate: function () {
            var valid = false;
            var selectedValues = [];
            var expectedValues = this.getExpectedValue();
            var checkedValues = [];

            for (var i = 0; i < this.fields.length; i++) {
                var selected = this.isSelectable() ? this.fields[i].isSelected() : true;
                var fieldValue = this.fields[i].getValue();
                if (this.type === 'text') {
                    fieldValue = fieldValue.toLowerCase();
                }
                
                if (selected && selectedValues.indexOf(fieldValue)) {
                    selectedValues.push(fieldValue);

                    if (this.fields[i].isLocked()) {
                        checkedValues.push(fieldValue);
                    }
                }

                if (!this.fields[i].isLocked() && selected) {
                    var validField = expectedValues.indexOf(fieldValue) >= 0 && checkedValues.indexOf(fieldValue) < 0;
                    if ('text' === this.type) {
                        validField = compareTexts(fieldValue, this.fields[i].getExpectedValue());
                    }

                    this.fields[i].mark(validField);
                    if (validField) {
                        checkedValues.push(fieldValue);

                        this.addPoints(this.fields[i].getPoint());
                        this.fields[i].setLocked(true);
                    }
                }
            }

            if (this.isMultiple()) {
                valid = checkedValues.length === expectedValues.length;
            } else if (!this.multiple && expectedValues[0] === selectedValues[0]) {
                valid = true;
            }

            if (valid) {
                for (var i = 0; i < this.fields.length; i++) {
                    this.fields[i].setLocked(true);
                }
            }

            return valid;
        },
        handleCheck: function (item) {
            if (!item.isSelected()) {
                return;
            }
            
            if (this.multiple) {
                var selected = 0;
                for (var i = 0; i < this.fields.length; i++) {
                    if (this.fields[i].isSelected()) {
                        selected++;
                    }
                }

                if (selected > this.values.length) {
                    item.setSelected(false);
                }

                return;
            }

            for (var i = 0; i < this.fields.length; i++) {
                if (this.fields[i].value !== item.value) {
                    this.fields[i].setSelected(false);
                }
            }
        },
        getHTML: function () {
            this.content = $('<div>', {
                class: 'question-item ' + this.type,
                html: '<div class="question">' + this.getText(this.question) + '</div>',
            });

            var inputContent = $('<div>', {
                class: 'inputs-outer display-flex flex-wrap'
            });

            for (var i = 0; i < this.fields.length; i++) {
                inputContent.append(this.fields[i].getHTML());
            }

            this.content.append(inputContent);

            return this.content;
        },
        initFields: function () {
            switch (this.type) {
                case 'bool':
                    this.initBoolFields();
                    break;
                case 'toggle':
                    this.initToggleFields();
                    break;
                case 'checkable':
                    this.initCheckableFields();
                    break;
                case 'text':
                    this.initTextFields();
                    break;
                default: 
                    throw Error('Handle type: ' + this.type);
            }
        },
        initBoolFields: function () {
            this.fields.push(new MuravidekCheckableItem(this, {
                label: 'true_label',
                type: 'bool',
                value: true,
            }));
            
            this.fields.push(new MuravidekCheckableItem(this, {
                label: 'false_label',
                type: 'bool',
                value: false,
            }));
        },
        initToggleFields: function () {
            if (!this.isMultiple()) {
                this.fields.push(new MuravidekToggleItem(this, {
                    options: this.getOptions(),
                    size: 'small',
                }));

                return;
            }

            for (var i = 0; i < this.values.length; i++) {
                this.fields.push(new MuravidekToggleItem(this, {
                    options: this.getOptions(),
                    size: 'small',
                }));
            }
        },
        initCheckableFields: function () {
            for (var i = 0; i < this.options.length; i++) {
                this.fields.push(new MuravidekCheckableItem(this, {
                    label: this.options[i],
                    value: this.options[i],
                }));
            }
        },
        initTextFields: function () {
            var values = Array.isArray(this.values) ? this.values : [this.values]
            for (var i = 0; i < values.length; i++) {
                this.fields.push(new SingleTextItem(this, {
                    expectedValue: this.getText(values[i]),
                    dynamic: false,
                    options: this.getFieldOptions(),
                }));
            }
        },
        setQuestion: function (question) {
            this.question = question;
        },
        getQuestion: function() {
            return this.question;
        },
        setValues: function (values) {
            this.values = values;
        },
        getValues: function () {
            return this.values;
        },
        setMultiple: function (multiple) {
            this.multiple = multiple;
        },
        isMultiple: function () {
            return this.multiple;
        },
        getText: function (key) {
            return this.task.getText(key);
        },
        addPoints: function (point) {
            this.task.addPoints(point);
        },
        isEnabled: function () {
            return this.task.isEnabled();
        },
        setType: function (type) {
            this.type = type;
        },
        setItems: function (items) {
            this.items = items;
        },
        setOptions: function (options) {
            if (!Array.isArray(options)) {
                return;
            }

            this.options = options;
        },
        getOptions: function () {
            return this.options;
        },
        setFieldOptions: function (fieldOptions) {
            this.fieldOptions = $.extend({}, this.fieldOptions, fieldOptions);
        },
        getFieldOptions: function () {
            return this.fieldOptions;
        },
        isSelectable: function () {
            switch (this.type) {
                case 'checkable':
                case 'bool':
                    return true;
                default:
                    return false;
            }
        },
        getExpectedValue: function () {
            var values = this.getValues();
            var expectedValues = Array.isArray(values) ? values : [values];
            if (this.type !== 'text') {
                return expectedValues;
            }

            var translations = [];
            for (var i = 0; i < expectedValues.length; i++) {
                translations.push(this.getText(expectedValues[i]).toLowerCase());
            }

            return translations;
        },
        getMissingValues: function () {
            var missingValues = [];
            var expectedValues = this.getExpectedValue();
            var checkedValues = [];

            for (var i = 0; i < this.fields.length; i++) {
                if (this.fields[i].isLocked()) {
                    checkedValues.push(this.fields[i].getValue());
                }
            }

            for (var i = 0; i < expectedValues.length; i++) {
                if (checkedValues.indexOf(expectedValues[i]) < 0) {
                    missingValues.push(expectedValues[i]);
                }
            }

            return missingValues;
        },
    }

    function QuestionAnswerGame(task, params) {
        try {
            this.task = task;
            this.questions = [];
            this.points = 0;
            this.content = null;
            this.layout = 'default';
            this.hint = null;

            if (params) {
                this.setParams(params);
            }
        } catch (error) {
            console.error(error);
        }
    }

    QuestionAnswerGame.prototype = {
        setParams: function (params) {
            if (params.layout) this.setLayout(params.layout);
            if (params.questions) this.initQuestions(params.questions);
            if (params.hint) this.setHint(params.hint);
        },
        getHTML: function () {
            this.content = $('<div>', {
                class: 'game-section questions-game-section ' + (this.layout ? this.layout : ''),
            });

            if (this.getHint()) {
                this.content.append($('<div>', {
                    class: 'hint-content questions',
                    html: this.getText(this.getHint()),
                }));
            }

            for (var i = 0; i < this.questions.length; i++) {
                this.content.append(this.questions[i].getHTML());
            }

            return this.content;
        },
        start: function () {
            for (var i = 0; i < this.questions.length; i++) {
                this.questions[i].start();
            }
        },
        stop: function () {},
        reveal: function () {
            for (var i = 0; i  < this.questions.length; i++) {
                this.questions[i].reveal();
            }
        },
        destroy: function () {
            for (var i = 0; i < this.questions.length; i++) {
                this.questions[i].destroy();
            }

            this.content.remove();
        },
        validate: function () {
            var valid = true;
            for (var i = 0; i < this.questions.length; i++) {
                if (!this.questions[i].validate()) {
                    valid = false;
                }
            }

            return valid;
        },
        initQuestions: function (questions) {
            if (!Array.isArray(questions)) {
                return;
            }

            for (var i = 0; i < questions.length; i++) {
                this.questions.push(new QuestionItem(this, questions[i]));
            }
        },
        addPoints: function (point) {
            this.points += point * this.task.getMultiplier();
        },
        getPoints: function () {
            return this.points;
        },
        getText: function (key) {
            return this.task.getText(key);
        },
        setLayout: function (layout) {
            this.layout = layout;
        },
        getLayout: function () {
            return this.layout;
        },
        isEnabled: function () {
            return this.task.enabled;
        },
        setHint: function (hint) {
            this.hint = hint;
        },
        getHint: function () {
            return this.hint;
        }
    };

    wnd.QuestionAnswerGame = QuestionAnswerGame;
})(window);