(function(wnd) {
    function CardItem(task, params) {
        try {
            this.task = task;
            this.content = '';
            this.values = null;
            this.image = null;
            this.retinaImage = null;
            this.fields = {};
            this.label = null;
            this.initialValues = {};

            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.log(error);
        }
    }

    CardItem.prototype = {
        init: function () {
            this.initFields();
        },
        setParams: function (params) {
            if (params.image) this.setImage(params.image);
            if (params.retinaImage) this.setRetinaImage(params.retinaImage);
            if (params.initialValues) this.setInitialValues(params.initialValues);
            if (params.values) this.setValues(params.values);
            if (params.label) this.setLabel(params.label);
        },
        getHTML: function () {
            this.content = $('<div>', {
                class: 'card-item',
            });

            if (this.image) {
                var attributes = '';
                if (this.retinaImage) {
                    attributes = 'data-srcset="' + this.image + ' 1x, ' + this.retinaImage + ' 2x"';
                }
                var image = '<img src="' + base_url + this.image + '" ' + attributes + ' class="lazy" />';

                this.content.append('<div class="image-outer">' + image + '</div>');
            }

            if (this.label) {
                this.content.append('<div class="title">' + this.getText(this.label) + '</div>');
            }

            var fields = this.getFieldKeys();
            var inputContent = $('<div>', {
                class: 'fields',
            });

            for (var i = 0; i < fields.length; i++) {
                var row = $('<div>,', {
                    class: 'row',
                });

                for (var j = 0; j < this.fields[fields[i]].length; j++) {
                    row.append(this.fields[fields[i]][j].getHTML());
                }
                
                if (this.fields[fields[i]].length > 1) {
                    row.addClass('multi-row');
                }

                inputContent.append(row);
            }

            this.content.append(inputContent);

            return this.content;
        },
        setImage: function (image) {
            this.image = image;
        },
        setRetinaImage: function (retinaImage) {
            this.retinaImage = retinaImage;
        },
        setValues: function (values) {
            this.values = values;
        },
        setInitialValues: function (initialValues) {
            this.initialValues = initialValues;
        },
        getInitialValues: function () {
            return this.initialValues;
        },
        getInitialValue: function (key) {
            return this.initialValues[key] || null;
        },
        setLabel: function (label) {
            this.label = label;
        },
        getLabel: function () {
            return this.label;
        },
        isEnabled: function () {
            return this.task.isEnabled();
        },
        initFields: function () {
            for (var i = 0; i < this.task.fields.length; i++) {
                var field = this.task.fields[i];
                var label = field.label && this.task.fields.length > 1 ? field.label : null;
                var initialValue = this.getInitialValue(field.key);
                var values = this.getValidValue(field.key);
                if (values && !Array.isArray(values)) {
                    values = [values];
                }
                
                this.fields[field.key] = [];
                var fieldCount = values ? values.length : 1;
                for (var j = 0; j < fieldCount; j++) {
                    var fieldObj = null;

                    switch (field.type) {
                        case 'toggle':
                            fieldObj = new MuravidekToggleItem(this, {
                                label: label,
                                options: field.options,
                                key: field.key,
                                value: initialValue,
                                size: field.size || null,
                                shuffleOptions: field.shuffleOptions,
                            });
                            break;
                        case 'tag':
                            var options = {};
                            if (typeof field.lockInitValue !== 'undefined') {
                                options.lockInitValue = field.lockInitValue;
                            }

                            fieldObj = new MuravidekTagItem(this, {
                                label: label,
                                key: field.key,
                                value: initialValue,
                                options: options,
                            });
                            break;
                        case 'label':
                            fieldObj = {
                                key: field.key,
                                type: 'label',
                                label: this.getText(initialValue),
                                value: initialValue,
                                getHTML: function () {
                                    return '<div class="label-item">' + this.label + '</div>'
                                },
                                isLocked: function () {
                                    return true;
                                },
                                mark: function () {},
                                getPoint: function () {
                                    return 0;
                                },
                                getValue: function () {
                                    return this.value;
                                }
                            };
                            break;
                        default:
                            throw new Error('Handle field type: ' + field.type);
                    }

                    this.fields[field.key].push(fieldObj);
                }
            }
        },
        getText: function (key) {
            return this.task.getText(key);
        },
        validate: function () {
            var valid = true;
            var keys = this.getFieldKeys();
            
            for (var i = 0; i < keys.length; i++) {
                var values = this.values[keys[i]];
                var selectedValues = [];
                var checkedValues = [];
                
                for (var j = 0; j < this.fields[keys[i]].length; j++) {
                    var validField = true;
                    var field = this.fields[keys[i]][j];

                    if (selectedValues.indexOf(field.value) < 0) {
                        selectedValues.push(field.value);
                    }
    
                    if (Array.isArray(values)) {
                        validField = false;
                        if (values.indexOf(field.value) >= 0 && checkedValues.indexOf(field.value) < 0) {
                            validField = true;
                            checkedValues.push(field.value);
                        }
                    }
    
                    if (!Array.isArray(values) && values !== selectedValues[0]) {
                        validField = false;
                    }
    
                    if (!validField) {
                        valid = false;
                    }
    
                    if (!field.isLocked() && validField) {
                        this.task.addPoints(field.getPoint());
                    }
    
                    field.mark(validField);
                }
            }
            
            return valid;
        },
        start: function () {
            this.callFieldsFunction('start');
        },
        stop: function () {},
        reveal: function () {
            var keys = this.getFieldKeys();
            for (var i = 0; i < keys.length; i++) {
                var missingValues = this.getMissingValues(keys[i]);
                var fieldType = this.task.getFieldType(keys[i]);
                if (!fieldType) {
                    console.error('Type not found for key: ' + keys[i]);
                }
                
                for (var j = 0; j < this.fields[keys[i]].length; j++) {
                    var field = this.fields[keys[i]][j];
                    if (!field.isLocked()) {
                        switch (fieldType) {
                            case 'tag':
                                var value = missingValues.shift();
                                field.reveal(value);
                                break;
                            case 'toggle':
                                var value = missingValues.shift();
                                field.reveal(value);
                                break;
                        }
                    }
                }
            }
        },
        destroy: function () {
            this.callFieldsFunction('destroy');

            this.content.remove();
        },
        callFieldsFunction: function (method) {
            var keys = this.getFieldKeys();
            for (var i = 0; i < keys.length; i++) {
                for (var j = 0; j < this.fields[keys[i]].length; j++) {
                    if (typeof this.fields[keys[i]][j][method] === 'function') {
                        this.fields[keys[i]][j][method]();
                    }
                }
            }
        },
        getFieldKeys: function () {
            return Object.keys(this.fields);
        },
        getValidValue: function (key) {
            return this.values[key];
        },
        getMissingValues: function (key) {
            var expectedValues = this.getValidValue(key);
            var missingValues = [];
            var checkedValues = [];

            if (!Array.isArray(expectedValues)) {
                expectedValues = [expectedValues];
            }

            for (var i = 0; i < this.fields[key].length; i++) {
                if (this.fields[key][i].isLocked()) {
                    checkedValues.push(this.fields[key][i].getValue());
                }
            }

            for (var i = 0; i < expectedValues.length; i++) {
                if (checkedValues.indexOf(expectedValues[i]) < 0) {
                    missingValues.push(expectedValues[i]);
                }
            }

            return missingValues;
        }
    }

    function TableRowItem(task, params, tableParams) {
        try {
            this.index = 0;
            this.numbered = false;

            CardItem.apply(this, arguments);
            
            if (tableParams) {
                this.setTableParams(tableParams);
            }
        } catch (error) {
            console.error(tableParams)
        }
    }

    TableRowItem.prototype = $.extend({}, CardItem.prototype, {
        setTableParams: function (params) {
            if (typeof params.index !== 'undefined') this.setIndex(params.index);
            if (typeof params.numbered !== 'undefined') this.setNumbered(params.numbered);
        },
        getHTML: function () {
            this.content = $('<div>', {
                class: 'table-row'
            });

            if (this.isNumbered()) {
                this.content.append('<div class="table-col serial"><div class="counter">' + (this.getIndex() + 1) + '</div></div>');
            }

            var fields = this.getFieldKeys();
            for (var i = 0; i < fields.length; i++) {
                var col = $('<div>', {
                    class: 'table-col'
                });

                for (var j = 0; j < this.fields[fields[i]].length; j++) {
                    col.append(this.fields[fields[i]][j].getHTML());
                }

                if (this.fields[fields[i]].length > 1) {
                    col.addClass('multi-col');
                }

                this.content.append(col);
            }

            return this.content;
        },
        setIndex: function (index) {
            this.index = index;
        },
        getIndex: function () {
            return this.index;
        },
        setNumbered: function (numbered) {
            this.numbered = numbered;
        },
        isNumbered: function () {
            return this.numbered;
        }
    });

    function DefaultGame(task, params) {
        try {
            this.task = task;
            this.items = [];
            this.fields = [];
            this.content = null;
            this.points = 0;
            this.gameHandler = null;
            this.prevButton = null;
            this.nextButton = null;
            this.layout = null;
            
            this.options = {
                slideOnMobile: false,
                colCount: null,
                numbered: false,
                headers: [],
                tagHint: null,
            };

            this.slideInitialized = null;
            this.slideIndex = 0;
            
            if (params) {
                this.setParams(params);
            }
        } catch (error) {
            console.error(error);
        }
    }

    DefaultGame.prototype = {
        addDOMEvents: function () {
            var _self = this;

            if (this.options.slideOnMobile) {
                this.content.on('muravidek.window_width_changed', function (evt, width) {
                    _self.checkMobileSlideVisibility();
                });

                this.prevButton.on('click', function () {
                    _self.slideIndex--;
                    _self.slideContent();
                });

                this.nextButton.on('click', function () {
                    _self.slideIndex++;
                    _self.slideContent();
                });
            }
        },
        checkMobileSlideVisibility: function () {
            if (!this.options.slideOnMobile) {
                return;
            }

            if (window.innerWidth > 600) {
                if (this.slideInitialized) {
                    this.slideInitialized = false;
                    this.prevButton.hide();
                    this.nextButton.hide();
                    this.slideIndex = 0;
                    this.content.find('.items').removeAttr('style');
                    this.content.removeClass('initialized');
                }

                return;
            }
            
            if (this.slideInitialized) {
                return;
            }

            this.slideInitialized = true;
            this.content.addClass('initialized');
            this.prevButton.hide();

            if (this.items.length > 1) {
                this.nextButton.show();
            }
        },
        slideContent: function () {
            if (this.slideIndex <= 0) {
                this.prevButton.hide();
                this.slideIndex = 0;
            }

            if (this.slideIndex >= this.items.length - 1) {
                this.nextButton.hide();
                this.slideIndex = this.items.length - 1;
            }

            if (this.slideIndex > 0) {
                this.prevButton.show();
            }

            if (this.slideIndex < this.items.length - 1) {
                this.nextButton.show();
            }

            this.content.find('.items').css({
                transform: 'translateX(-' + (this.slideIndex * 100) + 'vw)',
            });
        },
        setParams: function (params) {
            if (params.options) this.setOptions(params.options);
            if (params.layout) this.setLayout(params.layout);
            if (params.fields) this.setFields(params.fields);
            if (params.items) this.setItems(params.items);
        },
        initGameHandler: function (type) {
            switch (type) {
                case 'dragAndDrop':
                    this.gameHandler = new DragAndDropHandler(this, {
                        fields: this.fields,
                        hint: this.options.tagHint,
                        options: {
                            shuffleTags: typeof this.options.shuffleTags !== 'undefined' ? this.options.shuffleTags : true,
                            multiple: typeof this.options.multipleTags !== 'undefined' ? this.options.multipleTags : false,
                            containerClass: this.options.slideOnMobile ? 'mark-on-mobile' : null,
                        },
                    });
                    break;
                default:
                    throw Error('Undefined game handler: ' + type);
            }
        },
        getHTML: function () {
            switch (this.layout) {
                case 'cards':
                    this.drawCardsHTML();
                    break;
                case 'table':
                    this.drawTableHTML();
                    break
                default:
                    throw Error('Handle draw function of layout: ' + this.layout);
            }            

            this.addDOMEvents();

            return this.content;
        },
        drawCardsHTML: function () {
            var classes = [];

            if (this.options.colCount) {
                classes.push('col-' + this.options.colCount);
            }

            if (this.options.slideOnMobile) {
                classes.push('slide-on-mobile');
                classes.push('resize-sensitive');

                this.prevButton = $('<div>', {
                    class: 'step-button prev',
                    html: svg('step-arrow'),
                });

                this.nextButton = $('<div>', {
                    class: 'step-button next',
                    html: svg('step-arrow'),
                });
            }

            this.content = $('<div>', {
                class: 'game-section card-items-section ' + (classes ? classes.join(' ') : ''),
            });

            if (this.prevButton) {
                this.content.append(this.prevButton);
            }

            if (this.nextButton) {
                this.content.append(this.nextButton);
            }

            var itemsContent = $('<div>', {
                class: 'items',
            })

            for (var i = 0; i < this.items.length; i++) {
                itemsContent.append(this.items[i].getHTML())
            }

            this.content.append(itemsContent);
        },
        drawTableHTML: function () {
            var classes = [];

            if (this.options.colCount) {
                classes.push('col-' + this.options.colCount);
            }

            if (this.options.numbered) {
                classes.push('numbered-table');
            }

            this.content = $('<div>', {
                class: 'game-section table-game-section',
            });

            var table = $('<div>', {
                class: 'table ' + classes.join(' '),
            });

            if (!this.options.numbered && this.options.headers.length) {
                var row = $('<div>', {
                    class: 'table-row header-row'
                });

                for (var i = 0; i < this.options.headers.length; i++) {
                    row.append('<div class="table-col header-col">' + this.getText(this.options.headers[i]) + '</div>');
                }
                
                table.append(row);
            }

            for (var i = 0; i < this.items.length; i++) {
                table.append(this.items[i].getHTML());
            }

            this.content.append(table);
        },
        getGameHandler: function () {
            return this.gameHandler;
        },
        start: function () {
            for (var i = 0; i < this.items.length; i++) {
                this.items[i].start();
            }

            this.checkMobileSlideVisibility();
        },
        stop: function () {},
        reveal: function () {
            for (var i = 0; i < this.items.length; i++) {
                this.items[i].reveal();
            }
        },
        destroy: function () {
            for (var i = 0; i < this.items.length; i++) {
                this.items[i].destroy();
            }

            if (this.options.slideOnMobile) {
                this.content.off('muravidek.window_width_changed');
                this.prevButton.off('click');
                this.nextButton.off('click');
            }

            this.content.remove();
        },
        validate: function () {
            var valid = true;

            for (var i = 0; i < this.items.length; i++) {
                if (!this.items[i].validate()) {
                    valid = false;
                }
            }

            return valid;
        },
        addPoints: function (point) {
            this.points += point * this.task.getMultiplier();
        },
        getPoints: function () {
            return this.points;
        },
        isEnabled: function () {
            return this.task.enabled;
        },
        setFields: function (fields) {
            if (!Array.isArray(fields)) {
                this.fields = fields;
            }

            this.fields = fields;
        },
        getFields: function () {
            return this.fields;
        },
        setItems: function (items) {
            if (!Array.isArray(items)) {
                return;
            }

            for (var i = 0; i < items.length; i++) {
                var itemObj = null;

                switch (this.layout) {
                    case 'cards':
                        itemObj = new CardItem(this, items[i]);
                        break;
                    case 'table':
                        itemObj = new TableRowItem(this, items[i], {
                            index: i,
                            numbered: this.options.numbered || false,
                        });
                        break;
                    default: 
                        throw Error('Handle layout type: ' + this.layout);
                }

                if (itemObj) {
                    this.items.push(itemObj);
                }
            }
        },
        getText: function (key) {
            return this.task.getText(key);
        },
        setOptions: function (options) {
            this.options = $.extend(true, {}, this.options, options);
        },
        setLayout: function (layout) {
            this.layout = layout;
        },
        getFieldType: function (key) {
            for (var i = 0; i < this.fields.length; i++) {
                if (this.fields[i].key === key) {
                    return this.fields[i].type;
                }
            }

            return null;
        },
    };

    wnd.DefaultGame = DefaultGame;
})(window);