(function (wnd) {
    function SpellingGame(task, params) {
        try {
            this.task = task;
            this.content = null;
            this.words = [];
            this.values = [];
            this.description = null;
            this.fields = [];
            this.points = 0;

            if (params) {
                this.setParams(params);
            }
        } catch (error) {
            console.error(error);
        }
    }

    SpellingGame.prototype = {
        setParams: function (params) {
            if (params.description) this.setDescription(params.description);
            if (params.words) this.setWords(params.words);
            if (params.values) this.setValues(params.values);
        },
        getHTML: function () {
            this.content = $('<div>', {
                class: 'game-section spelling-game-section',
            });

            this.createHintContent();
            this.createDescriptionContent();
            
            return this.content;
        },
        createHintContent: function () {
            var words = [];
            for (var i = 0; i < this.words.length; i++) {
                words.push(this.getText(this.words[i]));
            }

            var hintContent = $('<div>', {
                class: 'hint-content words',
                html: '<strong>' + this.getText('given_words') + '</strong> ' + words.join(', '),
            });

            this.content.append(hintContent);
        },
        createDescriptionContent: function () {
            var _self = this;
            var fieldIndex = 0;
            var values = this.getValues();

            var descriptionText = this.getText(this.getDescription());
            descriptionText = descriptionText.replace(/%FIELD%/g, function () {
                return '<span class="field-outer"></span>';
            });

            var descriptionContent = $('<div>', {
                class: 'description',
                html: descriptionText,
            });
            
            descriptionContent.find('.field-outer').each(function() {
                var item = new SingleTextItem(_self, {
                    expectedValue: _self.getText(values[fieldIndex]),
                    dynamic: true,
                });

                fieldIndex++;

                $(this).append(item.getHTML());
                _self.fields.push(item);
            });
            
            this.content.append(descriptionContent);
        },
        start: function () {
            for (var i = 0; i < this.fields.length; i++) {
                this.fields[i].start();
            }
        },
        stop: function () {
            for (var i = 0; i < this.fields.length; i++) {
                this.fields[i].stop();
            }
        },
        reveal: function () {
            for (var i = 0; i < this.fields.length; i++) {
                if (!this.fields[i].isLocked()) {
                    this.fields[i].reveal();
                }
            }
        },
        destroy: function () {
            for (var i = 0; i < this.fields.length; i++) {
                this.fields[i].destroy();
            }

            this.content.remove();
        },
        validate: function () {
            var valid = true;

            for (var i = 0; i < this.fields.length; i++) {
                var field = this.fields[i];
                var validField = compareTexts(field.getExpectedValue(), field.getValue());

                if (!validField) {
                    valid = false;
                }

                if (!field.isLocked()) {
                    field.mark(validField);

                    if (validField) {
                        this.addPoints(field.getPoint());
                    }
                }
            }

            return valid;
        },
        getText: function (key) {
            return this.task.getText(key);
        },
        isEnabled: function () {
            return this.task.isEnabled();
        },
        addPoints: function (point) {
            this.points += point * this.task.getMultiplier();
        },
        getPoints: function () {
            return this.points;
        },
        setDescription: function (description) {
            this.description = description;
        },
        getDescription: function () {
            return this.description;
        },
        setWords: function (words) {
            this.words = words;
        },
        getWords: function () {
            return this.words;
        },
        setValues: function (values) {
            this.values = values;
        },
        getValues: function () {
            return this.values;
        },
    };

    wnd.SpellingGame = SpellingGame;
})(window);