(function(wnd) {
    function MuravidekGame(container, params) {
        try {
            if (!container.length) {
                throw Error('Container is required!');
            }

            this.container = container;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    MuravidekGame.prototype = {
        container: undefined,
        tasks: [],
        currentStep: 0,
        points: 0,
        title: '',
        texts: {},
        titleContent: null,
        stepContent: null,
        instructionsContent: null,
        taskPointsContent: null,
        reachedPointsContent: null,
        taskContainer: null,
        timerItem: null,
        init: function () {
            this.createElements();
            this.setElements();
            this.addDOMEvents();

            this.draw();
        },
        setElements: function () {
            this.titleContent = this.container.find('[data-purpose="task-title"]');
            this.instructionsContent = this.container.find('[data-purpose="task-instructions"]');
            this.stepContent = this.container.find('[data-purpose="current-step"]');
            this.taskPointsContent = this.container.find('[data-purpose="task-points"]');
            this.taskContainer = this.container.find('[data-purpose="task-container"]');
            this.reachedPointsContent = $('[data-purpose="reached-points"]');
            this.timerItem = $('[data-purpose="timer-item"]');
            this.imageContent = this.container.find('[data-purpose="task-image"]');
        },
        addDOMEvents: function () {

        },
        setParams: function (params) {
            if (params.texts) this.setTexts(params.texts);
            if (params.config) this.initConfig(params.config);
        },
        createElements: function () {
            this.createHeader();
            
            this.container.append('<div class="task-container" data-purpose="task-container"></div>');
            this.container.append('<a href="' + base_url + '" class="back-to-home">' + this.getText('back_to_home') + '</a>');
        },
        createHeader: function () {
            var header = $('<div>', {
                class: 'task-header',
                html: '<div class="image-container" data-purpose="task-image"></div>'
            });

            var texts = $('<div>', {
                class: 'text-container',
                html: '<div class="title">' + stripTags(this.getText(this.title)) + '</div><div class="texts"></div>'
            })

            var instructions = $('<div>',  {
                class: 'instructions',
                html: '<div class="name-row display-flex align-items-center"></div><div class="instruction" data-purpose="task-instructions"></div>',
            });

            instructions.find('.name-row').append('<div class="counter display-flex align-items-center"><div class="step" data-purpose="current-step"></div><div class="all">/'+ (this.tasks.length) +'</div></div><h2 data-purpose="task-title"></h2>');
            
            texts.find('.texts').append(instructions);
            texts.find('.texts').append('<div class="available-points display-flex align-items-end justify-flex-end"><div class="label">' + this.getText('available_points') + '</div><div class="point" data-purpose="task-points"></div></div>');

            header.append(texts);

            this.container.append(header)
        },
        initConfig: function (config) {
            if (config.title) this.setTitle(config.title);
            if (config.tasks) this.setTasks(config.tasks);
        },
        setTitle: function (title) {
            this.title = title;
        },
        setTexts: function (texts) {
            this.texts = $.extend({}, this.texts, texts);
        },
        setTasks: function (tasks) {
            if (!Array.isArray(tasks)) {
                return;
            }
            
            var maxPoints = 0;
            for (var i = 0; i < tasks.length; i++) {
                var obj = new MuravidekTask(this, tasks[i]);

                maxPoints += obj.getMaxPoints();
                if (i === tasks.length - 1) {
                    obj.setLast(true);
                }

                this.tasks.push(obj);
            }

            $('[data-purpose="all-points"]').html('/' + maxPoints);
        },
        draw: function () {
            var task = this.tasks[this.currentStep] || null;
            if (!task) {
                return;
            }

            this.stepContent.html(this.currentStep + 1);
            this.titleContent.html(this.getText(task.getTitle()));
            this.taskContainer.html('');

            var instruction = this.getText(task.getInstructions());
            if (instruction) {
                this.instructionsContent.html(instruction);
                this.instructionsContent.removeClass('hidden');
            } else {
                this.instructionsContent.addClass('hidden');
            }

            var hasDecoration = task.hasDecoration();
            if (hasDecoration) {
                this.updateDecorationContent(task);
            } else {
                this.imageContent.addClass('hidden');
                this.container.find('.task-header').removeClass('has-image');
            }

            this.taskPointsContent.html(task.getMaxPoints());

            task.draw();
            this.updateLazyLoad();
            
            task.start();
        },
        drawResult: function () {
            this.container.find('.task-header').hide();
            this.taskContainer.html('');

            var resultContainer = $('<div>', {
                class: 'result-container',
                html: '<h2 class="result-title">' + this.getText('result_page_title') + '</h2>'
            });

            var lead = this.getText('result_page_lead');
            if ('result_page_lead' !== lead) {
                resultContainer.append('<div class="lead">' + lead + '</div>');
            }

            resultContainer.append($('<div>', {
                class: 'points-row',
                html: '<div class="points-item">' +
                    '<div class="inner">' + 
                        '<div class="label">' + this.getText('all_points') + ':</div>' +
                        '<div class="points"><span class="reached">' + this.getReachedPoints() + '</span> /' + this.getMaxPoints() + '</div>' +
                    '</div></div>',
            }));

            var tasksList = $('<div>', {
                class: 'tasks-list',
            });

            for (var i = 0; i < this.tasks.length; i++) {
                tasksList.append(this.getTaskResultHtml(this.tasks[i], (i + 1)));
            }

            resultContainer.append(tasksList);

            resultContainer.append($('<div>', {
                class: 'button-row display-flex justify-flex-end',
                html: '<button class="button beige replay-button"><span class="text">' + this.getText('replay_button_label') + '</span>' + svg('repeat-icon') + '</button>'
            }));

            resultContainer.find('.replay-button').on('click', function () {
                document.location.reload();
            });

            this.taskContainer.append(resultContainer);            
        },
        getTaskResultHtml: function (task, step) {
            var content = $('<div>', {
                class: 'task-result-item',
            });

            content.append('<div class="counter-item"><span class="number">' + step + '</span>&nbsp;&nbsp;/ ' + this.tasks.length + '</div>');
            var textContent = $('<div>', {
                class: 'text-content',
            });

            textContent.append('<div class="names"><div class="task-list-title">' + stripTags(this.getText(this.title)) + '</div><div class="task-title">' + this.getText(task.getTitle()) + '</div></div>');
            textContent.append('<div class="points"><div class="reachable-point"><span class="label bold-fweight">'+ this.getText('reachable_point') + ':</span> ' + task.getMaxPoints() + '</div><div class="reached-points"><span class="label bold-fweight">' + this.getText('reached_point') + ':</span> ' + task.getPoints() + '</div></div>');

            content.append(textContent);

            return content;
        },
        updateDecorationContent: function (task) {
            var decoration = task.getDecoration();

            var mobileImage = decoration.mobileImage ?? null;
            var mobileRetinaImage = decoration.mobileRetinaImage ?? null;
            var image = decoration.image ?? null;
            var retinaImage = decoration.retinaImage ?? null;

            var src = mobileImage ?? image;
            var retinaSrc = mobileRetinaImage ?? retinaImage;

            var picture = $('<picture>');
            if (image && mobileImage) {
                picture.append('<source media="(min-width: 600.1px)" data-srcset="' + (base_url + image) + (retinaImage ? ' 1x, ' + (base_url + retinaImage) + ' 2x' : '') + '" />');
            }
            
            var attributes = '';
            if (retinaImage) {
                attributes = 'data-srcset="' + base_url + src + ' 1x, ' + base_url + retinaSrc + ' 2x"';
            }

            picture.append('<img src="' + base_url + src + '" '+ attributes + ' class="lazy" />');
            this.imageContent.html('');
            this.imageContent.append(picture);

            this.imageContent.removeClass('hidden');
            this.container.find('.task-header').addClass('has-image');
        },
        getText: function (key) {
            if (!key) {
                return '';
            }

            return this.texts[key] || key;
        },
        setTimerValue: function (value) {
            this.timerItem.find('.time').html(value);
        },
        updatePoints: function () {
            this.reachedPointsContent.html(this.getReachedPoints());
        },
        getReachedPoints: function () {
            var points = 0;
            for (var i = 0; i <= this.currentStep; i++) {
                if (typeof this.tasks[i] !== 'undefined') {
                    points += this.tasks[i].getPoints();
                }
            }

            return points;
        },
        getMaxPoints: function () {
            var maxPoints = 0;
            for (var i = 0; i < this.tasks.length; i++) {
                maxPoints += this.tasks[i].getMaxPoints();
            }

            return maxPoints;
        },
        goToNextStep: function () {
            this.tasks[this.currentStep].destroy();
            $('html, body').animate({scrollTop: 0}, 300);
            
            ++this.currentStep;
            if (this.currentStep === this.tasks.length) {
                this.drawResult();
            } else {
                this.draw();
            }
        },
        updateLazyLoad: function () {
            if (typeof window.lazyLoadInstance === 'undefined') {
                return;
            }

            window.lazyLoadInstance.update();
        }
    };

    wnd.MuravidekGame = MuravidekGame;
})(window);